<template>
    <div class="col-md-12">
        <div class="card card-container">
          <img @click="ApplyQRCode" :src="QRCodeSrc" alt="微信二维码图片"/>
          <span class="card-text">微信扫码登入</span>
        </div>
    </div>
</template>

<script>

import qrcode from "@/services/qrcode";
import {api} from "@/services/api";
import router from "@/router";


// 刷新是否进行中
let LoopRefreshMux = false
async function LoopRefreshQRCode() {
    LoopRefreshMux = true
    try {
        for (let i = 0; i < 10000; i++) {
            await new Promise(resolve => setTimeout(resolve, 1500)) // wait for 1.5 seconds
            const ticket = localStorage.getItem("qrcode.ticket")
            if ( ticket == null){
                continue
            }
            const response2 = await qrcode.QRCodeStatus(ticket)

            // 登入成功
            if (response2.data.statusCode === 20000) {
                let configure = JSON.parse(localStorage.getItem("jwt.configure"))
                location.href = configure.login_url +"?signal=" + response2.data.data.signal +
                    "&return_url=" + configure.return_url +
                    "&ticket=" + ticket
                break;
            }
        }
    } catch (error) {
        console.error(error)
    }
    LoopRefreshMux = false
}


export default {
    name: 'ScanLoginPage',
    data() {
        return {
            QRCodeSrc: "",
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    async mounted() {

        let return_url = router.currentRoute.value.query.return_url === 'undefined' ? document.referrer:router.currentRoute.value.query.return_url

        for (let i = 0; i < 10000; i++) {// 等待认证信息
            if (localStorage.getItem("authorization") == null){
                await new Promise(resolve => setTimeout(resolve, 500)) // wait for 0.5 seconds
                continue
            }
            break
        }

        let login_url
        try {
            let url = new URL(return_url);
            login_url = url.protocol + "//" + url.hostname + api.JwtLoginBaseApi
        }catch (e) {
            console.log(e)
            // TODO 默认跳转地址
            login_url  = "https://secself.com/"
            return_url = "https://secself.com/"
        }
        localStorage.setItem("jwt.configure", JSON.stringify({
            "return_url": return_url,
            "login_url": login_url,
        }))
        await this.ApplyQRCode()
    },
    methods:{
        ApplyQRCode: async function () {
            const response = await qrcode.ApplyQRCode(JSON.parse(localStorage.getItem("jwt.configure")))
            if(response.data.statusCode === 20000){
                this.QRCodeSrc = api.WXShowQRCodeURL + '?ticket=' +
                    encodeURIComponent(response.data.data.ticket)
                localStorage.setItem("qrcode.ticket", response.data.data.ticket)
            }
            if (!LoopRefreshMux){
                await LoopRefreshQRCode()
            }
        },
    }
};

</script>


<style scoped>

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.card-text{
    font-size: 1.5rem;
    margin: 0 auto 50px;

}

</style>
