import axios from 'axios';
import {api} from './api'

class AuthService {
  login(user) {
    return axios
      .post(api.FormLogin, {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(api.Register, {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }

  authHeader() {
        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.accessToken) {
            return { Authorization: 'Bearer ' + user.accessToken };
        } else {
            return {};
        }
    }
}

export default new AuthService();
