import axios from "axios";
import {api} from "@/services/api";
import md5 from "js-md5"
class Qrcode {
    ApplyQRCode(data) {
        return axios.post(
            api.QRCodeRefresh, data
        ).then(
            function (o) {
                return Promise.resolve(o)
            })
    }

  QRCodeStatus(ticket) {
    return axios.get(
        api.QRCodeStatus + "?ticketHash=" + md5(ticket) + "&t="+new Date().getTime()
    ).then(
        function (o) {
          return Promise.resolve(o)
        })
  }

}

export default new Qrcode();