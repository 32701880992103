import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {FontAwesomeIcon} from './plugins/font-awesome'
import axios from 'axios'
import {api} from "@/services/api";
const authorization = localStorage.getItem("authorization")
if ( authorization== null){
    axios.post(api.ApplyToken, {}).then(response=>{
        axios.defaults.headers.common['Authorization'] = response.headers.authorization
        localStorage.setItem("authorization", response.headers.authorization)
    })
}else {
    axios.defaults.headers.common['Authorization'] = authorization
}


createApp(App)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
