const SSO_API_PREFIX = "/api/v1"
const SSO_AUTH_DOMAIN = "sso.api.secself.com"
const SSO_PROTOCOL = "https"
const SSO_BASE_API_URL =  SSO_PROTOCOL + "://" + SSO_AUTH_DOMAIN + SSO_API_PREFIX
export const api = {
    TODO: SSO_BASE_API_URL+`/todo`,
    ApplyToken: SSO_BASE_API_URL+`/token`,
    FormLogin: SSO_BASE_API_URL+`/form-login`,
    Register: SSO_BASE_API_URL+`/register`,
    QRCodeRefresh: SSO_BASE_API_URL+`/qrcode/apply`,
    QRCodeStatus: SSO_BASE_API_URL+`/qrcode/status`,

    WXShowQRCodeURL: `https://mp.weixin.qq.com/cgi-bin/showqrcode`,
    JwtLoginBaseApi: '/sso/v1/jwt'
}